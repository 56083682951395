import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, doc, getDocs, addDoc, deleteDoc, query, where } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBrBaX2P3aTqU-lGwXWVupiq3eiyx1MO00",
  authDomain: "witcare2.firebaseapp.com",
  projectId: "witcare2",
  storageBucket: "witcare2.appspot.com",
  messagingSenderId: "251165446819",
  appId: "1:251165446819:web:2bcf313b54209a144f2516"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDHM90IAL3ocX0iICBzB_4EMu_iTQ2TKFg",
//   authDomain: "first-project-6a4a7.firebaseapp.com",
//   projectId: "first-project-6a4a7",
//   storageBucket: "first-project-6a4a7.appspot.com",
//   messagingSenderId: "494377168695",
//   appId: "1:494377168695:web:97bca7b51257e5073174b2"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyBrBaX2P3aTqU-lGwXWVupiq3eiyx1MO00",
//   authDomain: "witcare2.firebaseapp.com",
//   projectId: "witcare2",
//   storageBucket: "witcare2.appspot.com",
//   messagingSenderId: "251165446819",
//   appId: "1:251165446819:web:2bcf313b54209a144f2516"
// };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);
export const storage = getStorage(app);