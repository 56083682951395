import { sendPasswordResetEmail  } from 'firebase/auth'
import React, { useState } from 'react'
import { auth } from '../../firebase/firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';



const ForgotPassword = () => {

    const [email, setEmail] = useState()

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const user = await sendPasswordResetEmail(auth, email);
            toast.success("Reset password link send to your email successfully..", { position: 'top-center', autoClose: 1000 });
            setTimeout(() => {
                navigate("/");
            }, 1000);
            // localStorage.setItem("id", 0)
        } catch (error) {
            console.log("error", error)
            toast.error(error.message, {
                position: 'bottom-center'
            })
        }
    }

    return (

        <main className="container d-flex flex-column">
            <div className="row align-items-center justify-content-center g-0 min-vh-100">
                <div className="col-12 col-md-8 col-lg-6 col-xxl-4 py-8 py-xl-0">
                    {/* Card */}
                    <div className="card smooth-shadow-md">
                        {/* Card body */}
                        <div className="card-body p-6">
                            <div className="mb-4">
                                <Link to="/">
                                    <img
                                        src="/assets/img/logo.jpg"
                                        className="mb-2 text-inverse h-50 w-50"
                                        alt="Image"
                                    />
                                </Link>
                                <p className="mb-6 mt-2">Please enter your email to reset password.</p>
                            </div>
                            {/* Form */}
                            <form id="loginFrom" onSubmit={handleSubmit}>
                                <div
                                    id="alert-message"
                                    className="d-flex justify-content-center mb-3"
                                />
                                {/* Username */}
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">
                                        Email Address
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="form-control"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        name="email"
                                        placeholder="Email address here"
                                        required=""
                                    />
                                </div>
                                <div>
                                    {/* Button */}
                                    <div className="d-grid">
                                        <button type="submit" className="btn btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    )
}

export default ForgotPassword;
