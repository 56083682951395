import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../../../firebase/firebase";
import { signOut } from "firebase/auth";

const Sidebar = () => {
  const [user, setUser] = useState(() => {
    var user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  });
  const location = useLocation(); // Get current route

  const e = document.getElementById("nav-toggle");
  if (e) {
    e.addEventListener("click", function (e) {
      e.preventDefault();
      document.getElementById("main-wrapper").classList.toggle("toggled");
    });
  }

  const isActive = (path) => location.pathname === path;

  const LogoutHandler = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("accesstoken");
        toast.success("User signed out successfully..", {
          position: "top-center",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
        console.log("User signed out successfully");
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out:", error);
      });
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}

      <div className="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
        {/* Profile Sidebar */}
        <div className="profile-sidebar">
          <div className="widget-profile pro-widget-content">
            <div className="profile-info-widget">
              <a href="#" className="booking-doc-img">
                {/* <img src={user ? user.photoURL : "/assets/img/doctor.png"} alt="User Image" /> */}
                <img src={"/assets/img/doctor.png"} alt="User Image" />
              </a>
              <div className="profile-det-info">
                <h3>{user && user.displayName}</h3>
                <div className="patient-details">
                  <h5 className="mb-0">Psychiatrist, MD</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-widget">
            <nav className="dashboard-menu">
              <ul>
                <li className={isActive("/dashboard") ? "active" : ""}>
                  <Link to="/dashboard">
                    <i className="fas fa-columns"></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                <li className={isActive("/myappointments") ? "active" : ""}>
                  <Link to="/myappointments">
                    <i className="fas fa-calendar-check"></i>
                    <span>My Appointment</span>
                  </Link>
                </li>
                <li className={isActive("/myavailablity") ? "active" : ""}>
                  <Link to="/myavailablity">
                    <i className="fas fa-hourglass-start"></i>
                    <span>Schedule Timings</span>
                  </Link>
                </li>
                <li className={isActive("/chat") ? "active" : ""}>
                  <Link to="/chat">
                    <i className="fas fa-comments"></i>
                    <span>Message</span>
                  </Link>
                </li>
                <li className={isActive("/mypatients") ? "active" : ""}>
                  <Link to="/mypatients">
                    <i className="fas fa-user-injured"></i>
                    <span>My Patients</span>
                  </Link>
                </li>
                <li className={isActive("/questions") ? "active" : ""}>
                  <Link to="/questions">
                    <i className="fas fa-user-injured"></i>
                    <span>Questions</span>
                  </Link>
                </li>
                <li>
                  <Link onClick={LogoutHandler}>
                    <i className="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
