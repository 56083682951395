import React, { useEffect, useLayoutEffect, useState } from "react";
import Header from "../Partials/Header/Header";
import { db } from "../../firebase/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../Partials/Sidebar/Sidebar";
import Swal from "sweetalert2";
import moment from 'moment';
import emailjs from 'emailjs-com';


import ReactPaginate from "react-paginate";

const Dashboard = () => {
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [totalPatient, setTotalPatient] = useState(0);
  const [acceptedApp, setAcceptedApp] = useState(0);
  const [finishedApp, setFinishedApp] = useState(0);
  const [appointments, setAppointments] = useState([]);
  const [todayAppointments, setTodayAppointments] = useState();

  const appointmentsRef = collection(db, "appointments");

  const [filteredAppointments, setFilteredAppointments] = useState(); // Store filtered appointments
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [appointmentsPerPage] = useState(5); // Appointments per page
const emailSubject = "Appointment Update From Dr. Sandhya Chinala";


  async function getTotalAppointments() {
    try {
     const snapshot = await getDocs(appointmentsRef);
      const groupedData = {};
      snapshot.forEach((doc) => {
        const data = doc.data();
        const email = data.email;
        if (!groupedData[email]) {
          groupedData[email] = [];
        }
        groupedData[email].push({ id: doc.id, ...data });
      });
      return {pationtCount:Object.keys(groupedData).length,totalApp:snapshot.size};
      // return snapshot.size;
    } catch (error) {
      console.log("getTotalAppointments",error);
    }
  }

  async function fetchAppointmentsByQuery(field, operator, value) {
    try {
      const q = query(appointmentsRef, where(field, operator, value));
      const querySnapshot = await getDocs(q);

      const appointments = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAppointments(appointments);
      setFilteredAppointments(appointments.slice(0, appointmentsPerPage));
    } catch (error) {
      console.error("Error fetching appointments by query:", error);
    }
  }

  async function fetchAccepted() {
    try {
      const q = query(appointmentsRef, where("status", "==", "accepted"));
      const querySnapshot = await getDocs(q);
      setAcceptedApp(querySnapshot.size);
    } catch (error) {
      console.error("Error fetching appointments by query:", error);
    }
  }

  async function fetchFinished() {
    try {
      const q = query(appointmentsRef, where("status", "==", "finished"));
      const querySnapshot = await getDocs(q);
      setFinishedApp(querySnapshot.size);
    } catch (error) {
      console.error("Error fetching appointments by query:", error);
    }
  }

  const fetchTodayData = async () => {
    try {
      const now = new Date();

      const startOfDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        0,
        0,
        0
      );
      const endOfDay = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        23,
        59,
        59
      );

      const startTimestamp = Timestamp.fromDate(startOfDay);
      const endTimestamp = Timestamp.fromDate(endOfDay);
      // Query to get documents where timestamp is within today's range
      const todayQuery = query(
        appointmentsRef,
        where("status", "!=", "cancelled"),
        where("date", ">=", startTimestamp),
        where("date", "<=", endTimestamp)
      );

      const querySnapshot = await getDocs(todayQuery);

      const todayAppointments = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTodayAppointments(todayAppointments);
    } catch (error) {
      console.error("Error fetching today's data:", error);
    }
  };

  const handlePageClick = (selectedPage) => {
    const newPage = selectedPage.selected;
    const start = newPage * appointmentsPerPage;
    const end = start + appointmentsPerPage;

    setCurrentPage(newPage);
    setFilteredAppointments(appointments.slice(start, end));
  };

  // useEffect(() => {
  //   // updateDashboard();
  //   fetchAppointmentsByQuery("status", "==", "pending");
  //   // fetchTodayData();
  //   // fetchAccepted();
  //   // fetchFinished();
  // }, []);

  useLayoutEffect(() => {
    updateDashboard();
    fetchAppointmentsByQuery("status", "==", "pending");
    fetchTodayData();
    fetchAccepted();
    fetchFinished();
  }, []);
  async function updateDashboard() {
    const totalApps = await getTotalAppointments();
    setTotalAppointments(totalApps.totalApp);
    setTotalPatient(totalApps.pationtCount);
    // const pendingAppointments = await getPendingAppointmentsCount();
    // const acceptedAppointments = await getAcceptedAppointmentsCount();
    // const finishedAppointments = await getFinishedAppointmentsCount();
    // const todayAppointments = await getTodayAppointmentsCount();

    // console.log("Total Appointments:", totalAppointments);
    // console.log("Pending Appointments:", pendingAppointments);
    // console.log("Accepted Appointments:", acceptedAppointments);
    // console.log("Finished Appointments:", finishedAppointments);
    // console.log("Today's Appointments:", todayAppointments);

    // Update your dashboard UI with these values
  }

  const acceptAppointmentHandler = async (data) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to accept this appointment!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Accept it!",
      });

      if (result.isConfirmed) {
        const docRef = doc(db, "appointments", data.id);
        const status = "accepted";

        // Update the document in Firestore
        await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
        var message = `We have send this mail to inform you that your appointment ${data.appId} is Accepted.`;
        const formdata = {subject:emailSubject, email:data.email,name:data.name,message:message};
        sendEmail(formdata);
        // Filter out the accepted appointment and update the state
        const appointmentsFilter = appointments.filter((a) => a.id !== data.id);
        setAppointments(appointmentsFilter);

        // Show success message
        Swal.fire({
          title: "Accepted!",
          text: "The appointment has been accepted.",
          icon: "success",
        });
      }
    } catch (error) {
      console.log("Error in acceptAppointmentHandler:", error);
    }
  };

  const handleReject = async (data) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to reject this appointment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!",
    });
    if (result.isConfirmed) {
      const docRef = doc(db, "appointments", data.id);
      const status = "rejected";

      // Update the document in Firestore
      await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
      var message = `We have send this mail to inform you that your appointment ${data.appId} is Rejected.`;
      const formdata = {subject:emailSubject,email:data.email,name:data.name,message:message};
      sendEmail(formdata);
      // Filter out the accepted appointment and update the state
      const appointmentsFilter = appointments.filter((a) => a.id !== data.id);
      setAppointments(appointmentsFilter);
      Swal.fire({
        title: "Rejected!",
        text: "The appointment has been reject.",
        icon: "success",
      });
    }
  };

  const handleCancel = async (data) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel this appointment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    });
    if (result.isConfirmed) {
      const docRef = doc(db, "appointments", data.id);
      const status = "cancelled";

      // Update the document in Firestore
      await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
      var message = `We have send this mail to inform you that your appointment ${data.appId} is Canceled.`;
      const formdata = {subject:emailSubject,email:data.email,name:data.name,message:message};
      sendEmail(formdata);
      // Filter out the accepted appointment and update the state
      const appointmentsFilter = todayAppointments.filter((a) => a.id !== data.id);
      setTodayAppointments(appointmentsFilter);
      Swal.fire({
        title: "Cancelled!",
        text: "The appointment has been cancelled.",
        icon: "success",
      });
    }
  };
  const sendEmail = (formData) => {
    emailjs.send(
      process.env.REACT_APP_SERVICE_ID,    // Replace with your EmailJS Service ID
      process.env.REACT_APP_TEMPLATE_ID_STATUS,   // Replace with your EmailJS Template ID
      formData,             // Data from the form fields
      process.env.REACT_APP_PUBLIC_KEY     // Replace with your EmailJS Public Key
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      // alert("Email sent successfully!");
    })
    .catch((error) => {
      console.error('FAILED...', error);
      // alert("Email failed to send.");
    });
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index-2.html">Home</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard
                  </li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Dashboard</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid ">
          <div className="row">
            <Sidebar />
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="row">
                <div className="col-md-12">
                  <div className="card dash-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget dct-border-rht">
                            <div className="circle-bar circle-bar1">
                              <div className="circle-graph1" data-percent={totalPatient}>
                                <img
                                  src="assets/img/icon-01.png"
                                  className="img-fluid"
                                  alt="patient"
                                />
                              </div>
                            </div>
                            <div className="dash-widget-info">
                              <h4>Total Patient</h4>
                              <h3>{totalPatient}</h3>
                              <p className="text-muted">Till Today</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget">
                            <div className="circle-bar circle-bar3">
                              <div
                                className="circle-graph3"
                                data-percent={totalAppointments}
                              >
                                <img
                                  src="assets/img/icon-03.png"
                                  className="img-fluid"
                                  alt="Patient"
                                />
                              </div>
                            </div>
                            <div className="dash-widget-info">
                              <h5>Total Appoinments</h5>
                              <h3>{totalAppointments}</h3>
                              <p className="text-muted">
                                {new Date().toDateString()}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget dct-border-rht">
                            <div className="circle-bar circle-bar2">
                              <div
                                className="circle-graph2"
                                data-percent={
                                  todayAppointments
                                    ? todayAppointments.length
                                    : 0
                                }
                              >
                                <img
                                  src="assets/img/icon-03.png"
                                  className="img-fluid"
                                  alt="Patient"
                                />
                              </div>
                            </div>
                            <div className="dash-widget-info">
                              <h5>Todays Appointments</h5>
                              <h3>
                                {todayAppointments
                                  ? todayAppointments.length
                                  : 0}{" "}
                              </h3>
                              <p className="text-muted">
                                {new Date().toDateString()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card dash-card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget">
                            <div className="circle-bar circle-bar4">
                              <div className="circle-graph4" data-percent={appointments ? appointments.length : 0}>
                                <img
                                  src="assets/img/icon-03.png"
                                  className="img-fluid"
                                  alt="Patient"
                                />
                              </div>
                            </div>
                            <div className="dash-widget-info">
                              <h5>Pending Appoinments</h5>
                              <h3>{appointments ? appointments.length : 0}</h3>
                              <p className="text-muted">
                                {new Date().toDateString()}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget dct-border-rht">
                            <div className="circle-bar circle-bar3">
                              <div className="circle-graph3" data-percent={acceptedApp}>
                                <img
                                  src="assets/img/icon-03.png"
                                  className="img-fluid"
                                  alt="patient"
                                />
                              </div>
                            </div>
                            <div className="dash-widget-info">
                              <h5>Accepted Appointments</h5>
                              <h3>{acceptedApp}</h3>
                              <p className="text-muted">Till Today</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-4">
                          <div className="dash-widget dct-border-rht">
                            <div className="circle-bar circle-bar5">
                              <div className="circle-graph5" data-percent={finishedApp}>
                                <img
                                  src="assets/img/icon-03.png"
                                  className="img-fluid"
                                  alt="Patient"
                                />
                              </div>
                            </div>
                            <div className="dash-widget-info">
                              <h5>Finished Appoinment</h5>
                              <h3>{finishedApp}</h3>
                              <p className="text-muted">
                                {new Date().toDateString()}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h4 className="mb-4">Patient Appoinment</h4>
                  <div className="appointment-tab">
                    {/* Appointment Tab */}
                    <ul className="nav nav-tabs nav-tabs-solid nav-tabs-rounded">
                      <li className="nav-item">
                        <a
                          className="nav-link active "
                          href="#upcoming-appointments"
                          data-toggle="tab"
                        >
                          Upcoming
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#today-appointments"
                          data-toggle="tab"
                        >
                          Today
                        </a>
                      </li>
                    </ul>
                    {/* /Appointment Tab */}
                    <div className="tab-content">
                      {/* Upcoming Appointment Tab */}
                      <div
                        className="tab-pane show active"
                        id="upcoming-appointments"
                      >
                        <div className="card card-table mb-0">
                          <div className="card-b  ">
                            <div className="table-responsive">
                              <table className="table table-hover table-center mb-0">
                                <thead>
                                  <tr>
                                    <th>Patient Name</th>
                                    <th>App Date</th>
                                    <th>Booking Date</th>
                                    <th className="">Email</th>
                                    <th className="text-center">Description</th>
                                    {/* <th className="">Status</th> */}
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredAppointments &&
                                    filteredAppointments.map((v) => (
                                      <tr key={v.id}>
                                        <td>
                                          <h2 className="table-avatar">
                                            <a href="patient-profile.html">
                                              {v.name} <span>#{v.appId}</span>
                                            </a>
                                          </h2>
                                        </td>
                                        <td>

                                          {
                                            v.date
                                              ? moment(v.date).format("MM/DD/YYYY") // Change format as needed
                                              :
                                              v.date
                                                .toDate()
                                                .toLocaleString("en-US")
                                                .split(",")[0]

                                          }
                                          <span className="d-block text-info">
                                            {v.time.start +" - "+ v.time.end}
                                          </span>
                                        </td>
                                        <td>
                                          {
                                            v.createdAt
                                              .toDate()
                                              .toLocaleString("en-US")
                                              .split(",")[0]
                                          }
                                        </td>
                                        <td>{v.email}</td>
                                        <td className="text-center">
                                          {v.description}
                                        </td>
                                        <td className="text-right">
                                          <div className="table-action">
                                            <Link
                                              to={"/appointmentPending/" + v.id}
                                              className="btn btn-sm bg-info-light"
                                            >
                                              <i className="far fa-eye" /> View
                                            </Link>
                                            <button
                                              onClick={() =>
                                                acceptAppointmentHandler(v)
                                              }
                                              className="btn btn-sm bg-success-light mr-2"
                                              type="button"
                                            >
                                              <i className="fas fa-check" />{" "}
                                              Accept
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-sm bg-danger-light"
                                              onClick={() => handleReject(v)}
                                            >
                                              <i className="fas fa-times" />{" "}
                                              Reject
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                            {filteredAppointments && <ReactPaginate
                              previousLabel={"Previous"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              pageCount={Math.ceil(appointments.length / appointmentsPerPage)}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick}
                              containerClassName={"pagination justify-content-end mt-4"}
                              pageClassName={"page-item "}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              activeClassName={"active"}
                            />}
                          </div>
                        </div>
                      </div>
                      {/* /Upcoming Appointment Tab */}
                      {/* Today Appointment Tab */}
                      <div className="tab-pane" id="today-appointments">
                        <div className="card card-table mb-0">
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table table-hover table-center mb-0">
                                <thead>
                                  <tr>
                                    <th>Patient Name</th>
                                    <th>App Date</th>
                                    <th>Booking Date</th>
                                    <th className="">Email</th>
                                    <th className="text-center">Description</th>
                                    {/* <th className="">Status</th> */}
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  {todayAppointments &&
                                    todayAppointments.map((v) => (
                                      <tr key={v.id}>
                                        <td>
                                          <h2 className="table-avatar">
                                            <a href="patient-profile.html">
                                              {v.name} <span>#{v.appId}</span>
                                            </a>
                                          </h2>
                                        </td>
                                        <td>
                                          {
                                            v.date
                                              .toDate()
                                              .toLocaleString("en-US")
                                              .split(",")[0]
                                          }
                                          <span className="d-block text-info">
                                            {v.time.start +" - "+ v.time.end}
                                          </span>
                                        </td>
                                        <td>
                                          {
                                            v.createdAt
                                              .toDate()
                                              .toLocaleString("en-US")
                                              .split(",")[0]
                                          }
                                        </td>
                                        <td>{v.email}</td>
                                        <td className="text-center">
                                          {v.description}
                                        </td>
                                        <td className="text-right">
                                          <div className="table-action">
                                            <Link
                                              to={"/appointmentPending/" + v.id}
                                              className="btn btn-sm bg-info-light"
                                            >
                                              <i className="far fa-eye" /> View
                                            </Link>

                                            <button
                                              type="button"
                                              className="btn btn-sm bg-danger-light"
                                              onClick={() => handleCancel(v)}
                                            >
                                              <i className="fas fa-times" />{" "}
                                              Cancel
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /Today Appointment Tab */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
