import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Partials/Sidebar/Sidebar";
import { doc, setDoc,getDoc  } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import Swal from "sweetalert2";

const Questions = () => {
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleSelectChange = (id) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSubmit = () => {
    saveToFirestore(selectedOptions);
  };

  async function saveToFirestore(selectedOptions) {
    try {
      const docRef = doc(db, "questionEnable", "selectedOptions");
      await setDoc(docRef, selectedOptions);
      Swal.fire({
        title: "Success!",
        text: "Forms Section Updated Sucessfully.",
        icon: "success",
      });
      console.log("Document successfully written!");
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  }
  async function fetchFromFirestore() {
    try {
        const docRef = doc(db, "questionEnable", "selectedOptions");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setSelectedOptions(docSnap.data());
        } else {
          console.log("No such document!");
        }
    } catch (error) {
      console.error("Error writing document: ", error);
    }
  }
  const data = [
    { id: "History of Present Illness", text: "History of Present Illness" },
    { id: "MOOD", text: "MOOD" },
    { id: "ANXIETY", text: "ANXIETY" },
    { id: "PANIC", text: "PANIC" },
    { id: "SPECIFIC PHOBIA", text: "SPECIFIC PHOBIA" },
    { id: "SOCIAL ANXIETY", text: "SOCIAL ANXIETY" },
    { id: "MANIC SYMPTOMS", text: "MANIC SYMPTOMS" },
    { id: "ADHD SYMPTOMS", text: "ADHD SYMPTOMS" },
    { id: "SELF-INJURIOUS BEHAVIOR", text: "SELF-INJURIOUS BEHAVIOR" },
    {
      id: "OBSESSIVE-COMPULSIVE SYMPTOMS",
      text: "OBSESSIVE-COMPULSIVE SYMPTOMS",
    },
    { id: "EATING ISSUES", text: "EATING ISSUES" },
    { id: "TRAUMA SYMPTOMS", text: "TRAUMA SYMPTOMS" },
    { id: "REALITY TESTING", text: "REALITY TESTING" },
    { id: "SAFETY", text: "SAFETY" },
    { id: "SUBSTANCE USE DIFFICULTIES", text: "SUBSTANCE USE DIFFICULTIES" },
    { id: "NEUROCOGNITIVE SYMPTOMS", text: "NEUROCOGNITIVE SYMPTOMS" },
    { id: "Past History", text: "Past History" },
    { id: "MENTAL HEALTH HISTORY", text: "MENTAL HEALTH HISTORY" },
    {
      id: "PAST PSYCHOTHERAPEUTIC TREATMENT",
      text: "PAST PSYCHOTHERAPEUTIC TREATMENT",
    },
    { id: "PAST PSYCHIATRIC TREATMENT", text: "PAST PSYCHIATRIC TREATMENT" },
    { id: "MEDICAL HISTORY", text: "MEDICAL HISTORY" },
    { id: "NEUROLOGIC HISTORY", text: "NEUROLOGIC HISTORY" },
    { id: "BIRTH CONTROL", text: "BIRTH CONTROL" },
    { id: "MEDICATIONS", text: "MEDICATIONS" },
    { id: "FAMILY PSYCHIATRIC HISTORY", text: "FAMILY PSYCHIATRIC HISTORY" },
    { id: "FAMILY MEDICAL HISTORY", text: "FAMILY MEDICAL HISTORY" },
    { id: "EDUCATION/WORK HISTORY", text: "EDUCATION/WORK HISTORY" },
    { id: "SOCIAL HISTORY", text: "SOCIAL HISTORY" },
  ];
  useEffect(() => {
    // window.location.reload();
    fetchFromFirestore();
  }, []);
  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Questions
                  </li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">Questions</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <Sidebar />

            <div className="col-md-7 col-lg-8 col-xl-9">
                <h2 className="text-center my-5">Form Questions </h2>
              <div className="p-5 row">
                {data
                  .reduce((acc, item, index) => {
                    // Every 5 items, start a new column
                    if (index % 5 === 0) {
                      acc.push([]);
                    }
                    acc[acc.length - 1].push(item);
                    return acc;
                  }, [])
                  .map((columnItems, colIndex) => (
                    <div className="col-md-4 justify-content-center" key={colIndex}>
                      {columnItems.map((item) => (
                        <div key={item.id}>
                          <label>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={!!selectedOptions[item.id]}
                              onChange={() => handleSelectChange(item.id)}
                            />
                            {item.text}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
              <div className="text-center">
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                >
                    Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Questions;
