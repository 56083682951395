import React, { useState } from "react";
import { db, auth, storage } from "../../firebase/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import generateId from "../../utils/helpers/appId";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";

const BookAppointment = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [date, setDate] = useState("");
  const [dob, setDob] = useState("");
  const [time, setTime] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleUpload = async () => {
    if (!image) {
      alert("Please select an image first!");
      return;
    }

    const storageRef = ref(storage, `images/${image.name}`); // Create a reference to the file

    try {
      // Upload the file
      await uploadBytes(storageRef, image);
      alert("Image uploaded successfully!");

      // Get the download URL
      const url = await getDownloadURL(storageRef);
      setImageUrl(url); // Store the image URL
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const storageRef = ref(storage, `userProfiles/${image.name}`); // Create a reference to the file

  //     await uploadBytes(storageRef, image);

  //     const url = await getDownloadURL(storageRef);
  //     const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  //     const user = userCredential.user;
  //     await updateProfile(user, {
  //       displayName: name,
  //       photoURL: url,
  //     });

  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = auth.currentUser; // Get the currently authenticated user
    if (!user) {
      return;
    }
    if (!name || !email || !date || !time) {
      return;
    }

    try {
      var appId = generateId();

      const docRef = await addDoc(collection(db, "appointments"), {
        appId,
        name,
        email,
        date: new Date(date),
        time,
        description: "",
        status: "pending",
        isCanceled: false,
        userId: user.uid,
        dob: dob,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });

      //   setMessage(`Appointment booked successfully with ID: ${docRef.id}`);
      setName("");
      setEmail("");
      setDate("");
      setDob("");
      setTime("");
    } catch (err) {
      console.log("Error booking appointment: " + err.message);
    }
  };

  return (
    <div>
      <h2>Book an Appointment</h2>

      <form onSubmit={(e)=>
      {
        e.preventDefault();
        console.log("hello");
      }}>
        <input type="text" onChange={(e)=>console.log(e.target.value)} minLength={8} maxLength={10} />
        <button type="submit">Submit</button>
      </form>

      {/* <div>
          <input type="file" accept="image/*" onChange={handleChange} />
          <button onClick={handleUpload}>Upload Image</button>
          {imageUrl && (
            <div>
              <h3>Uploaded Image:</h3>
              <img src={imageUrl} alt="Uploaded" style={{ width: "300px" }} />
            </div>
          )}
        </div> */}
      <form onSubmit={handleSubmit}>
        <input
          className="form-control"
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          className="form-control"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          className="form-control"
          type="text"
          placeholder="descrip"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          // required
        />
        <input
          type="file"
          className="form-control"
          accept="image/*"
          onChange={handleChange}
        />
        <input
          className="form-control"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />
        <input
          className="form-control"
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          required
        />{" "}
        *
        <input
          type="file"
          className="form-control"
          accept="image/*"
          onChange={handleChange}
        />
        <input
          className="form-control"
          type="date"
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          required
        />
        <button type="submit">Book Appointment</button>
      </form>
    </div>
  );
};

export default BookAppointment;
