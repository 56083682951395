import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../Partials/Header/Header";
import Sidebar from "../Partials/Sidebar/Sidebar";

const PrivateRoute = ({ userDetail, onProjectChange, project }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [breadcrumb, setBreadcrumb] = useState("");
  const routes = {
    dashboard: "Dashboard",
    myappointments: "My Appointments",
    myavailablity: "Schedule Timings",
    appointmentPending: "My Appointments Details",
    chat: "Messages",
  };

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setBreadcrumb(routes[path] || "");
  }, [location.pathname]);
  const authorization = localStorage.getItem("accesstoken");

  const logCurrentOutlet = () => {
    console.log("Current Outlet: ", window.location.pathname);
  };

  const showHeader =
    location.pathname !== "/login" && location.pathname !== "/";

  return (
    <>
      {/* < onProjectChange={onProjectChange} projectId={ project} /> */}
      {/* <Header /> */}
      {showHeader && <Header />}

      {authorization ? (
        <Outlet onNavigate={logCurrentOutlet} />
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default PrivateRoute;
