import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { Button, Card, Modal } from 'react-bootstrap';
import feather from 'feather-icons';
import Swal from 'sweetalert2'
import Sidebar from '../Partials/Sidebar/Sidebar';


const AcceptedDetail = ({ appointment }) => {


    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSave = () => {
        handleClose();
    };

    const navigate = useNavigate()

    const handleFinish = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to finish this appointment!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes!"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Finished!",
                    text: "The appointment has been finished.",
                    icon: "success"
                });
                navigate('/myappointments')
            }
        });
    }

    const handleCancel = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to cancel this appointment!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, cancel it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Cancelled!",
                    text: "The appointment has been cancelled.",
                    icon: "success"
                });
                navigate('/myappointments')
            }
        });
    }


    const {
        id = 'So2',
        patientName = 'Asher Boyle',
        bookingDate = '31 August 2024',
        dateTime = 'Today - 12:00 pm',
        email = 'Boyle@gmail.com',
        description = '...',
        status = 'Accepted',
        questions = [
            { question: 'Have you taken any medication recently?', answer: 'No' },
            { question: 'Do you have any allergies?', answer: 'Yes, pollen' },
            { question: 'Are you experiencing any pain?', answer: 'Mild headache' },
            { question: 'Have you consulted any other doctor recently?', answer: 'No' },
        ],
    } = appointment || {};

    useEffect(() => {
        feather.replace();
    }, [])
    return (
        <>
            {/* <div main id="main-wrapper" className="main-wrapper "> */}
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <Link to="/myappointments">My Appointments</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        My Appointments Details
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">My Appointments Details</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className="container-fluid ">
                    <div className='row'>
                        <Sidebar />
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <Card className="appointment-card m-3 shadow-sm">
                                <Card.Header as="h5" className="appointment-header">Appointment Details</Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <div className="detail-container">
                                            <div className="detail-row">
                                                <div className="detail-item">
                                                    <strong>Appointment ID:</strong> {id}
                                                </div>
                                                <div className="detail-item">
                                                    <strong>Patient's Name:</strong> {patientName}
                                                </div>
                                            </div>
                                            <div className="detail-row">
                                                <div className="detail-item">
                                                    <strong>Status:</strong>
                                                    <span className={`badge badge-info-soft fs-2 ${status.toLowerCase()}`}>{status}</span>
                                                </div>
                                                <div className="detail-item">
                                                    <strong>Booking Date:</strong> {bookingDate}
                                                </div>
                                            </div>
                                            <div className="detail-row">
                                                <div className="detail-item">
                                                    <strong>Date & Time:</strong> {dateTime}
                                                </div>
                                                <div className="detail-item">
                                                    <strong>Email:</strong> {email}
                                                </div>
                                            </div>
                                            <div className="detail-row">
                                                <div className="detail-item">
                                                    <strong>Description:</strong> {description}
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-end button-group col-12 mt-4">
                                                <Link target='_blank' to="/chat?01" className="me-2 btn  btn-outline-secondary">
                                                    Chat
                                                </Link>
                                                <button variant="danger" className="me-2 btn btn-outline-success" onClick={handleFinish}>
                                                    Finish
                                                </button>
                                                <button className="btn btn-outline-danger" onClick={handleCancel}>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </Card.Text>

                                    <hr />

                                    <h6 className="questions-title">Questions & Answers:</h6>
                                    <ul className="questions-list">
                                        {questions.map((item, index) => (
                                            <li key={index} className="question-item">
                                                <strong>Q:</strong> {item.question} <br />
                                                <strong>A:</strong> {item.answer}
                                            </li>
                                        ))}
                                    </ul>

                                    <div className="d-flex justify-content-end button-group col-12 mt-4">
                                        <Link target='_blank' to="/chat" className="me-2 btn  btn-outline-secondary">
                                            Chat
                                        </Link>
                                        <button variant="danger" className="me-2 btn btn-outline-success" onClick={handleFinish}>
                                            Finish
                                        </button>
                                        <button className="btn btn-outline-danger" onClick={handleCancel}>
                                            Cancel
                                        </button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>


            {/* </div> */}




            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Start and End Time</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>



    )
}

export default AcceptedDetail