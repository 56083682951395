import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Partials/Sidebar/Sidebar";
import { db } from "../../firebase/firebase";
import { collection, getDocs, query, where, Timestamp } from "firebase/firestore";
import ReactPaginate from "react-paginate";
import moment from "moment";
import emailjs from 'emailjs-com';

const MyAppointments = () => {
  const [appointments, setAppointments] = useState([]); // Store all appointments
  const [filteredAppointments, setFilteredAppointments] = useState([]); // Store filtered appointments
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [appointmentsPerPage] = useState(5); // Appointments per page

  const appointmentsRef = collection(db, "appointments");

  async function fetchAppointmentsByQuery(status) {
    try {
      let q = appointmentsRef;

      if (status) {
        q = query(appointmentsRef, where("status", "==", status));
        if (status === "today") {
          const now = new Date();
          const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
          const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

          const startTimestamp = Timestamp.fromDate(startOfDay);
          const endTimestamp = Timestamp.fromDate(endOfDay);

          q = query(
            appointmentsRef,
            where("date", ">=", startTimestamp),
            where("date", "<=", endTimestamp)
          );
        }
      }

      const querySnapshot = await getDocs(q);
      const fetchedAppointments = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAppointments(fetchedAppointments);
      setFilteredAppointments(fetchedAppointments.slice(0, appointmentsPerPage));
    } catch (error) {
      console.error("Error fetching appointments by query:", error);
    }
  }

  const handleFilterChange = (e) => {
    setCurrentPage(0); // Reset to the first page when filter changes
    fetchAppointmentsByQuery(e.target.value);
  };

  const handlePageClick = (selectedPage) => {
    const newPage = selectedPage.selected;
    const start = newPage * appointmentsPerPage;
    const end = start + appointmentsPerPage;

    setCurrentPage(newPage);
    setFilteredAppointments(appointments.slice(start, end));
  };

  const sendEmail = (formData) => {
    emailjs.send(
      process.env.REACT_APP_SERVICE_ID,    // Replace with your EmailJS Service ID
      process.env.REACT_APP_TEMPLATE_ID_STATUS,   // Replace with your EmailJS Template ID
      formData,             // Data from the form fields
      process.env.REACT_APP_PUBLIC_KEY     // Replace with your EmailJS Public Key
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      // alert("Email sent successfully!");
    })
    .catch((error) => {
      console.error('FAILED...', error);
      // alert("Email failed to send.");
    });
  };

  useEffect(() => {
    fetchAppointmentsByQuery(); // Fetch all appointments on initial render
  }, []);

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    My Appointments
                  </li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">My Appointments</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="appointments">
                <div className="col-3 d-flex justify-content-end mb-3">
                  <select className="custom-select" onChange={handleFilterChange}>
                    <option value="">Select Appointment</option>
                    <option value="pending">Pending Appointment</option>
                    <option value="accepted">Accepted Appointment</option>
                    <option value="finished">Finished Appointment</option>
                    <option value="cancelled">Cancelled Appointment</option>
                    <option value="today">Today's Appointment</option>
                  </select>
                </div>

                {filteredAppointments.map((a) => (
                  <div className="appointment-list" key={a.id}>
                    <div className="profile-info-widget">
                      <div className="profile-det-info">
                        <h4>
                          <span
                            className={
                              a.status === "pending"
                                ? "badge badge-warning-soft fs-2"
                                : a.status === "accepted"
                                  ? "badge badge-info-soft fs-2"
                                  : a.status === "finished"
                                    ? "badge badge-success-soft fs-2"
                                    : "badge badge-danger-soft fs-2"
                            }
                          >
                            {a.status.charAt(0).toUpperCase() + a.status.slice(1)}
                          </span>
                        </h4>
                        <h3>
                          <a href="patient-profile.html">{a.name}</a>
                        </h3>
                        <div className="patient-details">
                          <h5>
                            <i className="far fa-clock" />{" "}
                            {a 
                            ? (a.date instanceof Date
                              ? moment(a.date).format("MM/DD/YYYY HH:mm")
                              : moment(new Date(a.date)).format("MM/DD/YYYY ")) +", "+ a.time.start +" - " + a.time.end
                            : 'Invalid date'}
                            {/* {
                              a.date && a.date.toDate
                                ? moment(a.date.toDate()).format("MM/DD/YYYY") + " - " + a.time
                                : 'Invalid date or time'
                            }                           */}
                            </h5>
                          <h5>
                            <i className="fas fa-envelope" /> {a.email}
                          </h5>
                          <h5 className="mb-0">
                            <i className="fas fa-list" /> {a.description}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="appointment-action">
                      <Link
                        to={"/appointmentPending/" + a.id}
                        className="btn btn-sm bg-info-light"
                        data-toggle="modal"
                        data-target="#appt_details"
                      >
                        <i className="far fa-eye" /> View
                      </Link>
                    </div>
                  </div>
                ))}

                {/* Pagination */}
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={Math.ceil(appointments.length / appointmentsPerPage)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-end mt-4"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyAppointments;
