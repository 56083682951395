import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Partials/Header/Header";
import Sidebar from "../Partials/Sidebar/Sidebar";
import {
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider,
  updateEmail,
  updateProfile,
} from "firebase/auth";
import { db, auth } from "../../firebase/firebase";
import Swal from "sweetalert2";

const EditProfile = () => {
  const user = auth.currentUser;
  const [users, setUser] = useState(() => {
    var user = localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  });
  const [email, setEmail] = useState(users ? users.email : "");
  const [name, setName] = useState(users ? users.displayName : "");
  const [formData, setFormData] = useState({
    cp: "",
    np: "",
    conp: "",
  });

  const reauthenticate = async (currentPassword) => {
    const credential = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    try {
      await reauthenticateWithCredential(user, credential);
      console.log("Re-authentication successful!");
      return true;
    } catch (error) {
      console.error("Error re-authenticating:", error);
      return false;
    }
  };

  const changePassword = async (e,currentPassword, newPassword) => {
    e.preventDefault();
    
    const reauthenticated = await reauthenticate(currentPassword);

    if (reauthenticated) {
      try {
        await updatePassword(user, newPassword);
        Swal.fire({
          title: "Updated!",
          text: "Password updated successfully.",
          icon: "success",
        });
        setFormData({...formData,np:"",cp:""})
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: "Error while updating password",
          icon: "error",
        });
        console.error("Error updating password:", error);
      }
    } else {
      console.log("Re-authentication failed. Cannot update password.");
    }
  };

  const emailUpdateHandler = async (e) => {
    e.preventDefault();
    try {
      await updateProfile(user, {
        displayName: name,
      });
      Swal.fire({
        title: "Updated!",
        text: "Name updated successfully.",
        icon: "success",
      });
      localStorage.setItem(
        "user",
        JSON.stringify({ ...users, displayName: name })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const passwordInputChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    My Profile
                  </li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">My Profile</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid ">
          <div className="row">
            <Sidebar />
            <div className="col-md-7 col-lg-8 col-xl-9">
              <div className="container">
                <div className="main-body">
                  {/* /Breadcrumb */}
                  <div className="row gutters-sm">
                    <div className="col-md-12">
                      <div className="card mb-3">
                        <div className="card-body">
                          <div className="profile-container">
                            <div className="profile-header d-flex justify-content-center">
                              <h4 className="">Profile Update</h4>
                              {/* <p>Manage your profile details and password</p> */}
                            </div>
                            <form
                              className=""
                              id="profie_update"
                              onSubmit={emailUpdateHandler}
                            >
                              {/* Name Field */}
                              <div className="mb-4">
                                <label htmlFor="name" className="form-label">
                                  Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control edit_input"
                                  id="name"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  placeholder="Enter your name"
                                  required
                                />
                              </div>
                              {/* Email Field */}
                              <div className="mb-4">
                                <label htmlFor="email" className="form-label">
                                  Email
                                </label>
                                <input
                                  type="email"
                                  className="form-control edit_input"
                                  id="email"
                                  value={email}
                                  placeholder="Enter your email"
                                  readOnly
                                />
                              </div>

                              <button
                                type="submit"
                                className="btn btn-primary w-100"
                              >
                                Save Changes
                              </button>
                            </form>

                            <hr className="my-5" />
                            <form onSubmit={(e)=>changePassword(e,formData.cp,formData.np)}>
                              <h4 className="mt-4 mb-5 text-center">
                                Change Password
                              </h4>
                              {/* Current Password */}
                              <div className="mb-4">
                                <label
                                  htmlFor="currentPassword"
                                  className="form-label"
                                >
                                  Current Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control edit_input"
                                  id="currentPassword"
                                  name="cp"
                                  onChange={passwordInputChange}
                                  value={formData.cp}
                                  placeholder="Enter current password"
                                  required
                                />
                              </div>
                              {/* New Password */}
                              <div className="mb-4">
                                <label
                                  htmlFor="newPassword"
                                  className="form-label"
                                >
                                  New Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control edit_input"
                                  id="newPassword"
                                  value={formData.np}
                                  onChange={passwordInputChange}
                                  name="np"
                                  placeholder="Enter new password"
                                  required
                                />
                              </div>
                              {/* Confirm New Password */}
                              {/* <div className="mb-4">
                                <label
                                  htmlFor="confirmNewPassword"
                                  className="form-label"
                                >
                                  Confirm New Password
                                </label>
                                <input
                                  type="password"
                                  className="form-control edit_input"
                                  id="confirmNewPassword"
                                  onChange={passwordInputChange}
                                  placeholder="Confirm new password"
                                  value={formData.conp}
                                  name="conp"
                                  required
                                />
                              </div> */}
                              {/* Save Changes Button */}
                              <button
                                type="submit"
                                className="btn btn-primary w-100"
                              >
                                Save Changes
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
