import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import feather from 'feather-icons';
import { Button, Modal, Form, Table, Spinner } from 'react-bootstrap';
import Sidebar from '../Partials/Sidebar/Sidebar';
import { db } from '../../firebase/firebase'
import { collection, getDocs, addDoc, deleteDoc, doc, setDoc } from "firebase/firestore";
import Swal from 'sweetalert2';

// import 'bootstrap/dist/js/bootstrap.bundle.min';
// import 'bootstrap/dist/js/bootstrap.bundle.min';


const MyAvailability = () => {
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [timeSlots, setTimeSlots] = useState({});
    const [newSlot, setNewSlot] = useState({ start: "", end: "" });
    const [showAddModal, setShowAddModal] = useState(false);
    const [slots, setSlots] = useState([]);
    const [currentDay, setCurrentDay] = useState("monday");
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [editSlots, setEditSlots] = useState(slots[currentDay] || []);
    const [loading, setLoading] = useState(true); // Loading state




    const handleClose = () => setShow(false);
    const handleCloseModal = () => setShowEdit(false);
    const handleShow = () => setShow(true);

    const handleSave = () => {
        // Handle save logic here
        console.log('Start Time:', startTime);
        console.log('End Time:', endTime);
        handleClose();
    };

    const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];


    useEffect(() => {
        feather.replace();
    }, [])

    useEffect(() => {
        const fetchTimeSlots = async () => {
            try {
                setLoading(true);
                const slotsCollection = collection(db, "doctorAvailability");
                const slotSnapshot = await getDocs(slotsCollection);

                const slotData = slotSnapshot.docs.reduce((acc, doc) => {
                    const dayData = doc.data();
                    const dayKey = doc.id;  // Use the document ID as the day
                    acc[dayKey] = dayData.slots || []; // Fetch slots correctly
                    return acc;
                }, {});

                console.log('Fetched slots:', slotData); // Log fetched data
                setSlots(slotData); // Update slots state with fetched data
                setLoading(false); // Set loading to false after fetching
            } catch (error) {
                console.error("Error fetching time slots:", error);
                setLoading(false);
            }
        };

        fetchTimeSlots();
    }, [currentDay]);



    // Add a new slot
    // Modify your Add Slot handler to use the newSlot
    // Add a new slot
    const handleAddSlot = async () => {
        const { start, end } = newSlot;

        // Validate start and end times
        if (!start || !end) {
            alert("Please select valid start and end times.");
            return;
        }

        const currentDaySlots = slots[currentDay] || [];

        // Check if the exact slot already exists for the current day
        const isDuplicate = currentDaySlots.some(slot => slot.start === start && slot.end === end);

        if (isDuplicate) {
            Swal.fire({
                text: "This exact time slot already exists. Please choose a different time!",
                icon: "error"
            });
            return;
        }


        const updatedSlots = [...currentDaySlots, { start, end }];
        setSlots({ ...slots, [currentDay]: updatedSlots }); // Update local state with new slot

        try {
            // Update Firestore document with the new slot for the current day
            const docRef = doc(db, "doctorAvailability", currentDay);
            await setDoc(docRef, { slots: updatedSlots }, { merge: true });

            // Reset the form and close the modal
            setNewSlot({ start: "", end: "" });
            setIsAddModalOpen(false);
            console.log("Slot added successfully for:", currentDay);
        } catch (error) {
            console.error("Error adding slot:", error);
        }
    };


    const handleDeleteSlot = async (index) => {
        const updatedSlots = [...(slots[currentDay] || [])];

        // Remove the slot from the local state
        updatedSlots.splice(index, 1); // Remove the slot from the array

        setSlots({ ...slots, [currentDay]: updatedSlots }); // Update local state

        try {
            // Update Firestore document by removing the specific slot
            const docRef = doc(db, "doctorAvailability", currentDay);
            await setDoc(docRef, { slots: updatedSlots }, { merge: true });
            console.log("Slot deleted successfully for:", currentDay);

            // Show success alert
            Swal.fire({
                text: "Slot deleted successfully!",
                icon: "success"
            });
        } catch (error) {
            console.error("Error deleting slot:", error);
            Swal.fire({
                text: "An error occurred while deleting the slot.",
                icon: "error"
            });
        }
    };




    // Handle Save for Edit Modal (Save slot times after editing)

    // Open edit modal and load current day slots
    const handleEditClick = () => {
        const daySlots = slots[currentDay] || [];
        setEditSlots(daySlots);
        setShowEdit(true);
    };

    // Handle slot time changes in the modal
    const handleEditSlotTime = (index, type, value) => {
        const updatedSlots = [...editSlots];
        updatedSlots[index][type] = value;
        setEditSlots(updatedSlots);
    };

    // Save edited time slots to Firestore
    const handleSaveEdit = async () => {
        try {
            const docRef = doc(db, "doctorAvailability", currentDay);
            await setDoc(docRef, { slots: editSlots }, { merge: true });
            setSlots({ ...slots, [currentDay]: editSlots });
            setShowEdit(false);
            Swal.fire({
                text: "Slots updated successfully!",
                icon: "success"
            });
        } catch (error) {
            console.error("Error saving time slots:", error);
        }
    };


    return (
        <>
            <div className="breadcrumb-bar">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-12">
                            <nav aria-label="breadcrumb" className="page-breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Schedule Timings
                                    </li>
                                </ol>
                            </nav>
                            <h2 className="breadcrumb-title">Schedule Timings</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className="container-fluid ">
                    <div className='row'>
                        <Sidebar />
                        <div className="col-md-7 col-lg-8 col-xl-9">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">Schedule Timings</h4>
                                            <div className="profile-box">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="card schedule-widget mb-0">
                                                            {/* Schedule Header */}
                                                            <div className="schedule-header">
                                                                {/* Schedule Nav */}
                                                                <div className="schedule-nav">
                                                                    <ul className="nav nav-tabs nav-justified">
                                                                        {days.map(day => (
                                                                            <li className="nav-item" key={day}>
                                                                                <a
                                                                                    className={`nav-link ${currentDay === day ? "active" : ""}`}
                                                                                    data-toggle="tab"
                                                                                    href={`#slot_${day}`}
                                                                                    onClick={() => setCurrentDay(day)}
                                                                                >
                                                                                    {day.charAt(0).toUpperCase() + day.slice(1)}
                                                                                </a>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                                {/* /Schedule Nav */}
                                                            </div>
                                                            {/* /Schedule Header */}
                                                            {/* Schedule Content */}
                                                            <div className="tab-content schedule-cont">
                                                                <div id={`slot_${currentDay}`} className="tab-pane fade show active">
                                                                    <h4 className="card-title d-flex justify-content-between">
                                                                        <span>Time Slots for {currentDay.charAt(0).toUpperCase() + currentDay.slice(1)}</span>
                                                                        <div>
                                                                            <a
                                                                                className="edit-link mr-4"
                                                                                data-toggle="modal"
                                                                                // href="#edit_time_slot"
                                                                                onClick={handleEditClick}
                                                                            >
                                                                                <i className="fa fa-edit mr-1" />
                                                                                Edit
                                                                            </a>
                                                                            <a className="edit-link mr-4" onClick={() => setIsAddModalOpen(true)}>
                                                                                <i className="fa fa-plus-circle" /> Add Slot
                                                                            </a>
                                                                        </div>
                                                                        {/* <button onClick={() => setIsEditModalOpen(true)} disabled={selectedSlotIndex === null}> */}

                                                                    </h4>
                                                                    {/* Slot List */}
                                                                    {loading ? (
                                                                        <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
                                                                            <Spinner animation="border" variant="primary" />
                                                                        </div>
                                                                    ) : (
                                                                        <div className="doc-times">
                                                                            {(slots[currentDay] || []).map((slot, index) => (
                                                                                <div className="doc-slot-list" key={index}>
                                                                                    {slot.start} - {slot.end}
                                                                                    <a href="javascript:void(0)" className="delete_schedule" onClick={() => handleDeleteSlot(index)}>
                                                                                        <i className="fa fa-times" />
                                                                                    </a>
                                                                                </div>
                                                                            ))}

                                                                            {slots[currentDay]?.length === 0 && (
                                                                                <p className="text-muted mb-0">Not Available</p>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {/* /Schedule Content */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Edit Modal  */}
            <Modal show={showEdit} onHide={() => setShowEdit(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Time Slots for {currentDay.charAt(0).toUpperCase() + currentDay.slice(1)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <Spinner animation="border" variant="primary" />
                    ) : (
                        <Table bordered hover>
                            <thead>
                                <tr>
                                    <th>Slot</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                </tr>
                            </thead>
                            <tbody>
                                {editSlots.length > 0 ? (
                                    editSlots.map((slot, index) => (
                                        <tr key={index}>
                                            <td>Slot {index + 1}</td>
                                            <td>
                                                <Form.Control
                                                    type="time"
                                                    value={slot.start}
                                                    onChange={(e) => handleEditSlotTime(index, 'start', e.target.value)}
                                                />
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="time"
                                                    value={slot.end}
                                                    onChange={(e) => handleEditSlotTime(index, 'end', e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3">No time slots available.</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEdit(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveEdit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Add Slot Modal */}
            <Modal show={isAddModalOpen} onHide={() => setIsAddModalOpen(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Time Slot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Start Time</Form.Label>
                            <Form.Control
                                type="time"
                                value={newSlot.start}
                                onChange={(e) => setNewSlot({ ...newSlot, start: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>End Time</Form.Label>
                            <Form.Control
                                type="time"
                                value={newSlot.end}
                                onChange={(e) => setNewSlot({ ...newSlot, end: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setIsAddModalOpen(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleAddSlot}>
                        Add Slot
                    </Button>
                </Modal.Footer>
            </Modal>



        </>
    )
}

export default MyAvailability