import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../Partials/Header/Header'

const Profile = () => {
    return (
        <>
            {/* eslint-disable jsx-a11y/anchor-is-valid */}
            <div id="app-content">
                <div className="app-content-area">
                    <div className="bg-primary pt-10 pb-21 mt-n6 mx-n4" />
                    <div className="container-fluid mt-n22">
                        <div className="row">
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-">
                                            <h1 className="text-white">My Profile</h1>
                                        </div>
                                        <div className="col-sm-">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item ">
                                                    <Link to="/dashboard" className=" text-white">
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className="breadcrumb-item active">
                                                    <Link to="/profile" className=" text-white">
                                                        My Profile
                                                    </Link>
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                {/* /.container-fluid */}
                            </section>


                        </div>
                        <div className="col-12">
                            <div className="card">
                                <div className='card-body'>
                                    {/* <div className="container"> */}
                                        {/* <div className="card"> */}
                                            {/* <div className="row g-0"> */}
                                                <div className="container">
                                                    <div className="main-body">
                                                    
                                                        {/* /Breadcrumb */}
                                                        <div className="row gutters-sm">
                                                            <div className="col-md-4 mb-3">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <div className="d-flex flex-column align-items-center text-center">
                                                                            <img
                                                                                src="assets/images/avatar/doctor.png"
                                                                                alt="Admin"
                                                                                className=""
                                                                                width={150}
                                                                            />
                                                                            <div className="mt-3">
                                                                                <h4>Dr. Sandhya Chinala </h4>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card mb-3">
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-sm-3">
                                                                                <h5 className="mb-0 profile_text_heading">Name</h5>
                                                                            </div>
                                                                            <div className="col-sm-9 text-secondary profile_text">Sandhya Chinala</div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row">
                                                                            <div className="col-sm-3">
                                                                                <h6 className="mb-0 profile_text_heading">Email</h6>
                                                                            </div>
                                                                            <div className="col-sm-9 text-secondary profile_text">sandhya@gmail.com</div>
                                                                        </div>
                                                                        <hr />
                                                                        <div className="row">
                                                                            <div className="col-sm-3">
                                                                                <h6 className="mb-0 profile_text_heading">Password</h6>
                                                                            </div>
                                                                            <div className="col-sm-9 text-secondary profile_text">******</div>
                                                                        </div>
                                                                        <hr />

                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <Link to="/editprofile"
                                                                                    className="btn btn-outline-info "
                                                                                >
                                                                                    Edit
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                              
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            

                                            {/* </div> */}
                                        {/* </div> */}
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Profile