import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase/firebase";
import { Button, Card, Modal } from "react-bootstrap";
import feather from "feather-icons";
import Swal from "sweetalert2";
import Header from "../Partials/Header/Header";
import Sidebar from "../Partials/Sidebar/Sidebar";
import moment from "moment";
import emailjs from 'emailjs-com';
import {
  collection,
  getDoc,
  query,
  where,
  doc,
  updateDoc,
  serverTimestamp,
  Timestamp,
} from "firebase/firestore";

const PendingDetail = () => {
  const { appId } = useParams();
  const [appointment, setAppointment] = useState(null);
  const [show, setShow] = useState(false);
  const emailSubject = "Appointment Update From Dr. Sandhya Chinala";

  const appointmentsRef = collection(db, "appointments");

  useEffect(() => {
    fetchAppointmentsByQuery(appId);
  }, [appId]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function fetchAppointmentsByQuery(appId) {
    try {
      const docRef = doc(appointmentsRef, appId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        setAppointment({ id: docSnapshot.id, ...docSnapshot.data() });
      }
    } catch (error) {
      console.error("Error fetching appointments by query:", error);
    }
  }

  const handleSave = () => {
    handleClose();
  };

  const navigate = useNavigate();
  const openChat = (appointmentId) => {
    // Navigate to the chat page and pass appointmentId
    navigate(`/chat/${appointmentId}`);
  };

  const {
    name,
    createdAt,
    date,
    time,
    email,
    description,
    status,
    questions = [
      { question: "Have you taken any medication recently?", answer: "No" },
      { question: "Do you have any allergies?", answer: "Yes, pollen" },
      { question: "Are you experiencing any pain?", answer: "Mild headache" },
      {
        question: "Have you consulted any other doctor recently?",
        answer: "No",
      },
    ],
  } = appointment || {};

  const acceptAppointmentHandler = async (docId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You want to accept this appointment!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Accept it!",
      });

      if (result.isConfirmed) {
        const docRef = doc(db, "appointments", docId);
        const status = "accepted";

        // Update the document in Firestore
        await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
        var message = `We have send this mail to inform you that your appointment ${appointment.appId} is Accepted.`
        const formdata = {subject:emailSubject,email:email,name:name,message:message};
        sendEmail(formdata);
        setAppointment({ ...appointment, status });

        // Show success message
        Swal.fire({
          title: "Accepted!",
          text: "The appointment has been accepted.",
          icon: "success",
        });
      }
    } catch (error) {
      console.log("Error in acceptAppointmentHandler:", error);
    }
  };

  const handleReject = async (docId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to reject this appointment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!",
    });
    if (result.isConfirmed) {
      const docRef = doc(db, "appointments", docId);
      const status = "rejected";

      // Update the document in Firestore
      await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
       var message = `We have send this mail to inform you that your appointment ${appointment.appId} is Rejected.`
      const formdata = {subject:emailSubject,email:email,name:name,message:message};
      sendEmail(formdata);
      setAppointment({ ...appointment, status });

      Swal.fire({
        title: "Rejected!",
        text: "The appointment has been reject.",
        icon: "success",
      });
    }
  };

  const handleCancel = async (docId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to cancel this appointment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    });
    if (result.isConfirmed) {
      const docRef = doc(db, "appointments", docId);
      const status = "cancelled";

      await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
      var message = `We have send this mail to inform you that your appointment ${appointment.appId} is Cancelled.`
      const formdata = {subject:emailSubject,email:email,name:name,message:message};
      sendEmail(formdata);
      setAppointment({ ...appointment, status });

      Swal.fire({
        title: "Cancelled!",
        text: "The appointment has been cancelled.",
        icon: "success",
      });
    }
  };

  const handleFinish = async (docId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to finish this appointment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, finish it!",
    });
    if (result.isConfirmed) {
      const docRef = doc(db, "appointments", docId);
      const status = "finished";

      await updateDoc(docRef, { status, updatedAt: serverTimestamp() });
      var message = `We have send this mail to inform you that your appointment ${appointment.appId} is Finished.`;
      const formdata = {subject:emailSubject,email:email,name:name,message:message};
      sendEmail(formdata);
      setAppointment({ ...appointment, status });

      Swal.fire({
        title: "Finished!",
        text: "The appointment has been finished.",
        icon: "success",
      });
    }
  };

  const sendEmail = (formData) => {
    emailjs.send(
      process.env.REACT_APP_SERVICE_ID,    // Replace with your EmailJS Service ID
      process.env.REACT_APP_TEMPLATE_ID_STATUS,   // Replace with your EmailJS Template ID
      formData,             // Data from the form fields
      process.env.REACT_APP_PUBLIC_KEY     // Replace with your EmailJS Public Key
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        // alert("Email sent successfully!");
      })
      .catch((error) => {
        console.error('FAILED...', error);
        // alert("Email failed to send.");
      });
  };

  return (
    <>
      {/* <div main id="main-wrapper" className="main-wrapper "> */}
      <div className="breadcrumb-bar">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-12 col-12">
              <nav aria-label="breadcrumb" className="page-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    <Link to="/myappointments">My Appointments</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    My Appointments Details
                  </li>
                </ol>
              </nav>
              <h2 className="breadcrumb-title">My Appointments Details</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container-fluid ">
          <div className="row">
            <Sidebar />
            <div className="col-md-7 col-lg-8 col-xl-9">
              <Card className="appointment-card m-3 shadow-sm">
                <Card.Header as="h5" className="appointment-header">
                  Appointment Details
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <div className="detail-container">
                      <div className="detail-row">
                        <div className="detail-item">
                          <strong>Appointment ID:</strong>{" "}
                          {appointment && "#" + appointment.appId}
                        </div>
                        <div className="detail-item">
                          <strong>Patient's Name:</strong> {name}
                        </div>
                      </div>
                      <div className="detail-row">
                        <div className="detail-item">
                          <strong>Status:</strong>
                          <span
                            className={
                              appointment &&
                              (status == "pending"
                                ? `badge badge-warning-soft fs-2`
                                : status == "accepted"
                                  ? `badge badge-info-soft fs-2`
                                  : status == "finished"
                                    ? `badge badge-success-soft fs-2`
                                    : `badge badge-danger-soft fs-2`)
                            }
                          >
                            {appointment && status.charAt(0).toUpperCase() + status.slice(1)}
                          </span>
                        </div>
                        <div className="detail-item">
                          <strong>Booking Date:</strong>{" "}
                          {appointment && createdAt
                            ? moment(createdAt.toDate()).format("MM/DD/YYYY HH:mm") // Adjust format as needed
                            : 'Invalid date'}
                        </div>
                      </div>
                      <div className="detail-row">
                        <div className="detail-item">
                          <strong>Date & Time:</strong>{" "}
                          {appointment && date
                            ? (date instanceof Date
                              ? moment(date).format("MM/DD/YYYY HH:mm")
                              : moment(new Date(date)).format("MM/DD/YYYY ")) + ", " + time.start + " - " + time.end
                            : 'Invalid date'}
                        </div>
                        <div className="detail-item">
                          <strong>Email:</strong> {email}
                        </div>
                      </div>
                      <div className="detail-row">
                        <div className="detail-item">
                          <strong>Description:</strong> {description}
                        </div>
                      </div>
                      <div className="d-flex justify-content-end button-group col-12 mt-4">
                        {appointment && status == "pending" && (
                          <>
                            <button
                              type="button"
                              className="me-2 btn btn-outline-success"
                              onClick={() => acceptAppointmentHandler(appId)}
                            >
                              Accept
                            </button>
                            <button
                              type="button"
                              className="me-2 btn btn-outline-danger"
                              onClick={() => handleReject(appId)}
                            >
                              Reject
                            </button>
                          </>
                        )}
                        {appointment && status == "accepted" && (
                          <>
                            <Link
                              target="_blank"
                              to={{ pathname: '/chat', search: `?id=${appointment.id}` }}
                              className="me-2 btn  btn-outline-secondary"
                            >
                              Chat
                            </Link>
                            <button
                              variant="danger"
                              className="me-2 btn btn-outline-success"
                              onClick={() => handleFinish(appId)}
                            >
                              Finish
                            </button>
                            <button
                              type="button"
                              className="me-2 btn btn-outline-danger"
                              onClick={() => handleCancel(appId)}
                            >
                              Cancel
                            </button>
                          </>
                        )}
                        {appointment && status == "finished" && <Link
                          target="_blank"
                          to="/chat"
                          className="me-2 btn  btn-outline-secondary"
                        >
                          Chat
                        </Link>}
                        <button
                          type="button"
                          className="btn btn-outline-secondary"
                          onClick={() => window.history.back()}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </Card.Text>
                  <hr />
                  {appointment && appointment?.firstform_depressesed ?
                    <>
                      <h5>Questions:</h5>
                      <table className="table table-bordered mt-3">
                        <tbody>
                          <tr>
                            <th className="col-sm-8">1) Have you been feeling depressed lately?</th>
                            <td className="col-sm-4">{appointment.firstform_depressesed === 'yes' ? 'Yes' : 'No'}</td>
                          </tr>

                          <tr>
                            <th>2) Prescribed Mental Health Medications?</th>
                            <td>{appointment.firstform_mentalhealth === 'yes' ? 'Yes' : 'No'}</td>
                          </tr>

                          {appointment.firstform_mentalhealth === 'yes' && (
                            <tr>
                              <td colSpan="2">
                                <strong>Medication Details:</strong>
                                <ul>
                                  <li><strong>Name:</strong> {appointment.mentalHealthDetails.name}</li>
                                  <li><strong>Dosage:</strong> {appointment.mentalHealthDetails.dosage}</li>
                                  <li><strong>Frequency:</strong> {appointment.mentalHealthDetails.frequency}</li>
                                </ul>
                              </td>
                            </tr>
                          )}

                          <tr>
                            <th>3) Psychiatric Hospitalizations History</th>
                            <td>{appointment.firstform_psychiatric === 'yes' ? 'Yes' : 'No'}</td>
                          </tr>

                          {appointment.firstform_psychiatric === 'yes' && (
                            <tr>
                              <td colSpan="2">
                                <strong>Medical History:</strong> {appointment.medicalHistory}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>

                    </>
                  : <div>No questions found..</div>}
                  <hr />
                  <h6 className="questions-title">Forms:</h6>
                  <ul className="questions-list">

                    <li className="question-item">
                      <Link
                        to={appointment?.pdfPath1}
                        className="mb-3 "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Intake Questionnaire
                      </Link>
                    </li>
                    <li className="question-item">
                      <Link
                        to={appointment?.pdfPath2}
                        className="mb-3 "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        PHQ-9 and GAD-7
                      </Link>
                    </li>

                  </ul>
                  {/* <div className="d-flex justify-content-end button-group mt-4">
                    <button
                      className="me-2 btn btn-outline-success"
                      onClick={() => window.history.back()}
                    >
                      Accept
                    </button>
                    <button
                      variant="danger"
                      className="me-2 btn btn-outline-danger"
                      onClick={handleReject}
                    >
                      Reject
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      onClick={() => window.history.back()}
                    >
                      Back
                    </button>
                  </div> */}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}
    </>
  );
};

export default PendingDetail;
